// @codekit-prepend "extern/jquery.justifiedGallery.js"
// @codekit-prepend "extern/jquery.fancybox.js"
// @codekit-prepend "extern/swiper-min.js"

jQuery(document).ready(function($) {

	// CLICK FUNCTIES +++++++++++++++++++++++++++ //

	// Hamburger toggle -----------
	$('.hamburger').on("click", function(){
		$(this).toggleClass("actief");
		$('.nav-mobile-menu').toggleClass("actief");
	});

	// Algemene toggle -----------
	$('.toggle').on("click", function(){
		$(this).toggleClass("actief");
	});

	// Actieveren popup -----------
	$('.poptrig').on("click", function(){$('.popup').addClass("toon");$('body').addClass("noscroll");});
	$('.inner .sluiten ,body').on("click", function(){$('.popup').removeClass("toon");$('body').removeClass("noscroll");});
	$(".poptrig,.popup .inner").click(function(e){e.stopPropagation();});

	
	// COOKIES ++++++++++++++++++++++++++ //

	// melding-popup
	$('.melding-popup .sluiten').click(function() { $('.melding-popup').addClass('hidden');
	Cookies.set('melding-popup.hide','true', { expires: 7 });});
	if (Cookies.get('melding-popup.hide')) {$('.melding-popup').addClass('keep-hidden');}

	// melding-boven
	$('.melding-boven .sluiten').click(function() { $('.melding-boven').addClass('hidden');
	Cookies.set('melding-boven.hide','true', {element: '.melding-boven'}, { expires: 7 });});
	if (Cookies.get('melding-boven.hide')) {$('.melding-boven').addClass('keep-hidden');}

	// SCROLL FUNCTIES ++++++++++++++++++++++++++ //

	// Back to top -----------
	$('.backtotop').click(function(){
    	$("html, body").animate({ scrollTop: 0 }, 600);
    	return false;
	});

	$(window).scroll(function() {var scroll = $(window).scrollTop();
		if ($(window).scrollTop() > 400) {$(".backtotop").addClass("toon");}
	   else if ($(window).scrollTop() <= 400) {$(".backtotop").removeClass("toon"); }
	});

	$(window).scroll(function() {
	   if($(window).scrollTop() + $(window).height() == $(document).height()) { $('.backtotop').addClass("fix");}
	   else { $('.backtotop').removeClass("fix"); }
	});

	// VASTE VENDORS +++++++++++++++++++++++++++ //

	// Toewijzen justified gallery -----------
	if ($(".acf-justigal")[0]){ $(".acf-justigal").justifiedGallery(); }

	// CUSTOM SCRIPTS KOMEN HIER ONDER --------------------------------------------------------------------------//
	//-----------------------------------------------------------------------------------------------------------//

	//swiper-voorbeeld --------------------------------------
	var swiper = new Swiper('.swiper-voorbeeld', {
		autoplay: {delay: 5000,},
		speed: 300,
		autoHeight: false,
		grabCursor: true,
		loop: false,
		watchOverflow: true,
		centeredSlides: false,
		slidesOffsetBefore: 0,
		slidesOffsetAfter: 0,
		direction: 'horizontal',
		effect: 'slide', // 'slide', 'fade', 'cube', 'coverflow' or 'flip'
		breakpoints: { // breakpoints gelden voor alles erboven
			0: { 
				slidesPerView: 1,
				spaceBetween: 0
			},
			767: {
				slidesPerView: 2,
				spaceBetween: 10
			},
			992: {
				slidesPerView: 3,
				spaceBetween: 20
			}
		},	
		navigation: {
			nextEl: '.voorbeeld-next',
			prevEl: '.voorbeeld-prev',
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',// "bullets", "fraction", "progressbar"
			clickable: true,
		},
	});
	

});